import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  /**
   * Gestore degli errori
   * 
   * @param response La risposta di errore
   */
  handleError(response: HttpErrorResponse) {
    let message = "Errore nel server";
    switch (response.status) {
      case 400:
        switch (response.error['error']) {
          case 'invalid_grant':
            if (response.error['error_description'] === 'Password scaduta')
              message = "Password scaduta"
            else
              message = "Errore nella richiesta!";;
            break;
        }
      case 401:
        message = response.error['details'] ? response.error['details'] : 'Username o Password sbagliate!';
        break;
      case 403:
        message = response.error['message'] ? response.error['message'] : 'Forbidden';
        break;
      case 404:
        message = response.error['message'] ? response.error['message'] : 'Non Trovato!';
        break;
      case 409:
        if (response.error['internalCode']) {
          switch (response.error['internalCode']) {
            case 409085:
              message = "Utente già attivo";
              break;
            case 409086:
              message = "Email associata ad altra card";
              break;
            case 409099:
              message = "Buono sconto non redimibile";
              break;
          }
        } else
          message = response.error['message'] ? response.error['message'] : 'Conflitto!';
        break;
      case 500:
        message = "Errore nel server! Contattare amministratore";
        break
    }

    return throwError(message);
  }

}
