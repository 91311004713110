import { Component, OnInit } from '@angular/core';
import { Profile } from './beans/profile';
import { AuthService } from './services/auth/auth.service';
import { UserService } from './services/user/user.service';
import * as _moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLogged: boolean;
  profile: Profile;
  actualYear: string;

  constructor(private userService: UserService, private authService: AuthService) {
  }

  ngOnInit(): void {
    this.actualYear = _moment().format('YYYY');
    this.authService.onChengeLoginAuth().subscribe(res => {
      this.isLogged = res;
      if (this.isLogged)
        this.userService.user().subscribe(user => {
          this.profile = user;
          this.userService.setProfile(this.profile);
        });
    });
  }

  public logout() {
    this.authService.logout();
  }

}
