<div class="claim-container container" *ngIf="!thankyoupage">

    <div class="claim-content">
        <div class="claim-title">
            <h1>TRASFORMA I TUOI PUNTI NAU! IN BUONI SCONTO</h1>
            <p>SCEGLI SE RICHIEDERE SUBITO IL TUO BUONO SCONTO O CONTINUARE AD ACCUMULARE PUNTI PER AGGIUNGERE UN PREMIO ANCORA PIÙ IMPORTANTE</p>
        </div>

        <div class="box-scount-content">

            <div *ngFor="let reward of rewardList; let index = index">

                <div class="btn-trasform">

                    <div class="carousel">
                        <div class="change_outer">
                            <div class="change_inner">

                                <div class="scount-content" [ngStyle]="reward.redeemable ? {'border': '1px solid #000', 'color': '#000'} : {'border': '1px solid #7c7b7b', 'color': '#7c7b7b'}">
                                    <p class="bonus" [ngStyle]="reward.redeemable ? {'color': '#000'} : {'color': '#7c7b7b'}">BUONO SCONTO</p>
                                    <p class="price" [ngStyle]="reward.redeemable ? {'color': '#000'} : {'color': '#7c7b7b'}">{{reward.couponDiscountValue}}</p>
                                </div>

                                <div class="scount-content" [ngStyle]="reward.redeemable ? {'background': '#000', 'border': '1px solid rgb(0, 0, 0)'} : {'background': '#7c7b7b', 'border': '1px solid #7c7b7b'}">
                                    <p class="price price-top">{{reward.points}}</p>
                                    <p class="bonus points">PUNTI NAU!</p>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>

                <p class="request-nau" *ngIf="reward.redeemable && loading == -1" (click)="redemptionReward(reward?.code, index)">Richiedi premio</p>

                <mat-error *ngIf="loading == index">{{error}}</mat-error>

                <div class="spinner" *ngIf="loading == index">
                    <mat-spinner strokeWidth="10" mode="indeterminate" diameter="50"></mat-spinner>
                </div>

            </div>

        </div>

    </div>
    <hr>
</div>

<div id="thankyoupage" *ngIf="thankyoupage" class="container">

    <h1 class="mat-display-1">Grazie, ti abbiamo inviato una mail di conferma del premio.</h1>

</div>