<form class="survey-container container" [formGroup]="formSurvey" autocomplete="off" (ngSubmit)="survey()" *ngIf="!thankyoupage">

    <div class="survey-background">
        <div class="survey-content">
            <p class="customer-txt">{{profile?.firstname}} {{profile?.lastname}}</p>
            <p>è passato qualche giorno da quando hai scelto i nostri occhiali. Siccome siamo curiosi, ci piacerebbe sapere come sta andando e in cambio ti facciamo un regalo: buono €10 sui tuoi prossimi occhiali NAU!</p>
        </div>

        <div class="line-container">

            <div class="point-container">
                <div *ngFor="let question of resQuestions; let i = index" [attr.data-index]="i" class="mt">

                    <div class="line-content">

                        <div class="circle-container">
                            <div class="circle-line">
                                <p class="point-line">{{question.index}}</p>
                            </div>

                            <div class="separator-rv" *ngIf="i < resQuestions.length-1"></div>
                        </div>

                        <div class="radio-content">
                            <p class="point-1">{{question.question}} <a id="asterisk">*</a></p>

                            <mat-radio-group color="primary">
                                <a *ngFor="let check of question.options; let i = index" [attr.data-index]="i" [ngStyle]="question.layout != 'row' ? {'display': 'block', 'margin-top': '16px'} : {}">
                                    <mat-radio-button class="ml" value="{{i}}" (change)="checkRadio(check, question.index, i, formSurvey.get('responseNote').value, question.index)">{{check}}
                                        <textarea *ngIf="check === 'Altro' && other" matInput formControlName="responseNote" rows="5"></textarea>
                                    </mat-radio-button>
                                </a>
                            </mat-radio-group>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

    <div class="text-center">
        <mat-error *ngIf="error">Siamo spiacenti, ma il questionario non è inviabile.</mat-error>
    </div>

    <div class="submit-button" *ngIf="!loading">
        <p class="mtop-half">
            <input type="submit" value="invia" class="btn btn-primary" />
        </p>
    </div>

    <div class="spinner" *ngIf="loading">
        <mat-spinner strokeWidth="10" mode="indeterminate" diameter="50"></mat-spinner>
    </div>

</form>

<div id="thankyoupage" *ngIf="thankyoupage">

    <h1 class="mat-display-1">Grazie, abbiamo ricevuto la tua risposta</h1>

</div>
