import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Profile } from '../beans/profile';
import { RewardService } from '../services/reward.service';
import { UserService } from '../services/user/user.service';
import * as _moment from 'moment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
  profileActive: boolean;
  profile: Profile = new Profile;
  showReset: boolean;
  loading: boolean;
  email: string;
  error: string;
  thankyoupage: boolean;
  userNotActive: boolean;
  emptyReward: boolean;
  displayedColumns: string[];
  dataSource: MatTableDataSource<any>;
  recordsPerPage: number = 10;
  loadingProgress: boolean;
  total: number;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private rewardService: RewardService,
    private userService: UserService
  ) {
    this.displayedColumns = ['data', 'nome', 'puntinau'];
    this.dataSource = new MatTableDataSource<any>([]);
    this.loading = false;
    this.emptyReward = true;
  }

  ngAfterViewInit(): void {
    this.paginator._intl.itemsPerPageLabel = 'Elementi mostrati per pagina: ';
    this.paginator._intl.previousPageLabel = 'Pagina precedente';
    this.paginator._intl.nextPageLabel = 'Pagina successiva';
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length == 0 || pageSize == 0)
        return `0 di ${length}`;

      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} di ${length}`;
    }

  }

  ngOnInit(): void {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }, 50);

    this.profileActive = false;
    this.userService.onChangeProfile().subscribe(profile => {
      this.profile = profile;
      this.email = profile.email;
    });

    this.loadingProgress = true;
    this.rewardService.rewardRedeemed()
      .subscribe(data => {
        this.loadingProgress = false;
        this.dataSource.data = data.results;
        this.total = data.size;
        this.emptyReward = this.total > 0;
        this.dataSource.paginator = this.paginator;
      });
  }

  public requestResetPassword() {
    this.loading = true;
    this.showReset = false;

    this.userService.requestResetPassword(this.email).subscribe(
      res => {
        this.loading = false;
        this.showReset = false;
        this.thankyoupage = true;
      },
      (error) => {
        if (error === 'User not found.')
          this.thankyoupage = true;
        else if (error === 'USER_NOT_ACTIVE')
          this.userNotActive = true;
        else
          this.error = error;

        this.loading = false;
      },
      () => this.loading = false);
  }

}
