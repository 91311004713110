<div class="home-container-nau container">

    <div class="home-content">

        <div class="profile-box" *ngIf="profileActive">
            <h1>DATI PROFILO</h1>
            <p><a class="a-profile">Cognome:</a> {{profile?.lastname}}</p>
            <p><a class="a-profile">Nome:</a> {{profile?.firstname}}</p>
            <p><a class="a-profile">Data di Nascità:</a> {{profile?.birthday}}</p>
            <p><a class="a-profile">Genere:</a> {{profile?.gender}}</p>
            <p><a class="a-profile">Indirizzo:</a> {{profile?.address}}</p>
            <p><a class="a-profile">Località:</a> {{profile?.city}}</p>
            <p><a class="a-profile">Provincia:</a> {{profile?.province}}</p>
            <p><a class="a-profile">Codice Fiscale:</a> {{profile?.fiscalCode}}</p>
            <p><a class="a-profile">E-mail:</a> {{profile?.email}}</p>

            <p class="mtop-half" (click)="showReset = !showReset" *ngIf="!showReset && !loading">
                <input type="button" value="Modifica password" class="btn btn-primary-border b-white" [routerLink]="['']" />
            </p>

            <div class="mt-32" *ngIf="showReset && !loading">
                <p>Sei sicuro di voler modificare la password? <a (click)="requestResetPassword()">Sì</a> / <a (click)="showReset = !showReset">No</a></p>
            </div>

            <div class="mt-32" *ngIf="thankyoupage && !showReset && !loading">
                <p>Grazie, ti abbiamo inviato un'email per modificare la password</p>
            </div>

            <div class="mt-32 spinner" *ngIf="loading">
                <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
            </div>

            <p class="mbottom-double" *ngIf="!showReset && !loading">
                <a class="btn-link" (click)="profileActive = !profileActive">VISUALIZZA SALDO</a>
            </p>

        </div>

        <div class="profile-box" *ngIf="!profileActive">
            <h1 class="h1-profile">{{profile?.firstname | uppercase}} {{profile?.lastname | uppercase}}</h1>
            <p class="profile-code"><a>NAU!CARD:</a> {{profile?.loyalty}}</p>

            <div class="box-content-number">
                <div class="box-number">

                    <p class="number-nau">{{profile?.points != null ? profile?.points : '0' }}</p>
                    <p class="text-nau">PUNTI NAU!</p>

                </div>
            </div>

            <hr>

            <p class="p-600">PUOI RICHIEDERE UN PREMIO</p>

            <p class="mbottom-double">
                <a class="btn-link" (click)="profileActive = !profileActive">
                VISUALIZZA PROFILO
            </a>
        </div>


        <div class="profile-welcome">
            <h1>BENVENUTO<br> IN NAU!CARD</h1>
            <p>NAU!CARD premia la tua fedeltà: ogni nuovo acquisto contribuisce all'accumulo di punti NAU! che portrai trasformare in buoni sconto</p>
            <p class="mtop-half">
                <input type="button" value="Scopri come funziona" class="btn btn-primary-border b-white" [routerLink]="['/faq']" />
            </p>
        </div>
    </div>

    <div class="profile-content">

        <div class="box-content-1">
            <h3>I TUOI PREMI</h3>
            <p>CONSULTA L'ELENCO DEI PREMI OTTENUTI</p>
        </div>

        <div class="box-content-2">
            <div class="box">
                <p class="mbottom-double">
                    <img src="assets/images/icon-trasforma.png" alt="">
                    <a class="btn-link" [routerLink]="['/reward']">RICHIEDI PREMIO</a>
                </p>
            </div>

            <div class="box">
                <p class="mbottom-double"> <img src="assets/images/icon-storico.png" alt="">
                    <a class="btn-link" [routerLink]="['/countbalance']">ESTRATTO CONTO</a>
                </p>
            </div>
        </div>

    </div>

    <div class="bonus-container">

        <div class="countbalance-calendar-table">

            <mat-progress-bar *ngIf="loadingProgress" mode="indeterminate"></mat-progress-bar>

            <mat-table class="mat-elevation-z2" [dataSource]="dataSource" class="mat-elevation-z2">

                <ng-container matColumnDef="data">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> DATA </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="DATA"> {{ element.date | date: 'dd/MM/yyyy HH:mm' }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="nome">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> NOME </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="NEGOZIO"> {{ element.name }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="puntinau">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> PUNTI NAU! </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="PUNTI NAU!"> {{ element.points }} </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

            </mat-table>

            <mat-paginator #paginator [length]="total" [pageSize]="recordsPerPage"></mat-paginator>

        </div>

        <p *ngIf="!emptyReward">NON CI SONO PREMI</p>

    </div>

</div>