export class Profile {
    id?: number;
    email: string;
    firstname: string;
    lastname: string;
    fiscalCode: string;
    gender: string;
    birthday: string;
    address: string;
    civicNumber: any;
    zipCode: any;
    city: string;
    province: string;
    tcConsent: any;
    loyalty: any;
    mobileNumber: string;
    points: number;
}