import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ErrorService } from '../error.service';
import { environment as ENV } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private nauToken: string;
  private nauBaseUrl: string;
  private clientCredentials: string;
  private loginAuth: boolean;
  private subjectLoginAuth = new Subject<boolean>();
  private loginChange: EventEmitter<boolean>;

  constructor(private httpClient: HttpClient, private router: Router, private errorService: ErrorService, private authService: AuthService) {
    this.loginChange = new EventEmitter();
    this.nauBaseUrl = `${ENV.nauBaseUrl}`;

    this.clientCredentials = btoa(ENV.basicCredentials);
    this.nauToken = localStorage.getItem('nau_token') != null ? localStorage.getItem('nau_token') : this.getCookie('nau_token');

  }

  /**
  * Login user
  *
  * @param username La username
  * @param password La password
  * @param token Il token recaptcha
  */
  public login(username: string, password: string, remember: boolean, token?: string): Observable<void> {
    this.setSession(null);

    let payload = { username: username, password: password, platform: 'WEBAPP' };
    let headers = new HttpHeaders().set('Authorization', `Basic ${this.clientCredentials}`);

    return this.httpClient.post(`${this.nauBaseUrl}/auth/login`, payload, { headers: headers })
      .pipe(
        map((response: Response) => this.setSession(response, remember)),
        catchError(this.errorService.handleError)
      );
  }

  /**
   * sso login
   * @param token 
   */
  public ssoLogin(token: string): Observable<any> {
    return this.httpClient.get<any>(`${this.nauBaseUrl}/auth/sso/login?token=${token}`)
      .pipe(
        map((response: Response) => this.setSession(response, true)),
        catchError(this.errorService.handleError)
      );
  }

  /**
 * Logout user
 */
  public logout(): void {
    this.deleteCookies('nau_token');
    this.subjectLoginAuth.next(false);
    this.setSession(null);
  }

  /**
   * Restituisce il token
   */
  public getToken(): string {
    if (this.nauToken == '')
      return null;
    return this.nauToken;
  }

  /**
   * Controlla se il token è scaduto
   */
  public isAuthenticated(): boolean {
    const token = this.getToken();
    this.loginAuth = token !== null && token !== undefined
    this.subjectLoginAuth.next(this.loginAuth);
    return token !== null && token !== undefined;
  }

  /**
   * Event login tab
   * @returns 
   */
  public onChengeLoginAuth(): Observable<boolean> {
    return this.subjectLoginAuth.asObservable();
  }


  /**
   * Evento di login
   */
  public onLoginChange() {
    return this.loginChange;
  }

  //#region PRIVATE METHODS

  /**
   * Setta la sessione in localStorage
   * @param response La risposta del login
   * @param fromRefresh TRUE se il set session è chiamato dal refresh, FALSE o NULL altrimenti
   */
  private setSession(response: Response, remember?: boolean): void {
    if (response) {
      if (remember)
        localStorage.setItem('nau_token', response['token']);
      else
        this.setCookie('nau_token', response['token']);

      this.nauToken = response['token'];
      this.loginChange.emit(true);
    } else {
      localStorage.removeItem('nau_token');
      this.nauToken = undefined;
      this.loginChange.emit(false);
      this.router.navigate(['/login']);
    }
  }

  //#endregion

  /**
   * 
   * @param cname 
   * @returns 
   */
  private getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  /**
   * 
   * @param cname 
   * @param cvalue 
   */
  private setCookie(cname, cvalue) {
    document.cookie = cname + "=" + cvalue + ";" + ";path=/";
  }

  /**
   * 
   * @param name 
   */
  private deleteCookies(name: string) {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      if (cookie.includes(name)) {
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    }
  }

}




