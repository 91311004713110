import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../services/user/user.service';

@Component({
  selector: 'app-request-activate-account',
  templateUrl: './request-activate-account.component.html',
  styleUrls: ['./request-activate-account.component.scss']
})
export class RequestActivateAccountComponent implements OnInit {


  form: FormGroup;
  error: string;
  loading: boolean;
  thankyoupage: boolean;
  userNotActive: boolean;

  constructor(private formBuilder: FormBuilder, private userService: UserService) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      username: [null, [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,63}$")]]
    });
  }

  public requestResetPassword(values: { username: string }) {
    if (!this.form.valid) return;

    this.loading = true;
    this.userService.resendActivateToken(values.username).subscribe(
      (res) => this.thankyoupage = true,
      (error) => {
        if (error === 'User not found.')
          this.thankyoupage = true;
        else if (error === 'USER_NOT_ACTIVE')
          this.userNotActive = true;
        else
          this.error = error;

        this.loading = false;
      },
      () => this.loading = false
    );
  }

}
