import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user/user.service';

@Component({
  selector: 'app-reset-password-email',
  templateUrl: './reset-password-email.component.html',
  styleUrls: ['./reset-password-email.component.scss']
})
export class ResetPasswordEmailComponent implements OnInit {

  loading: boolean;
  form: FormGroup;
  token: string;
  error: string;
  thankyoupage: boolean;

  errorPasswordLength: boolean;
  errorPasswordUpper: boolean;
  errorPasswordLower: boolean;
  errorPasswordNumber: boolean;
  errorPasswordSpecial: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(res => this.token = res['token']);
    this.form = this.formBuilder.group({
      password: [null, [Validators.required, Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!\"#$%&'()*+,-.\/:;<=>?@\[\\\]^_`{|}~])[A-Za-z0-9!\"#$%&'()*+,-.\/:;<=>?@\[\\\]^_`{|}~]{8,30}$")]],
      confirmPassword: [null, Validators.required]
    },
      { validator: this.checkPassword() }
    );
  }

  public resetPassword(values: { password: string }) {
    if (this.form.valid) {
      this.loading = true;
      this.userService.resetPassword(this.token, values.password).subscribe(
        () => this.thankyoupage = true,
        (error) => {
          this.error = error;
          this.loading = false;
        }
      );
    }
  }

  private checkPassword() {
    return (group: FormGroup) => {
      const passwordInput = group.controls['password'];
      const confirmPasswordInput = group.controls['confirmPassword'];

      confirmPasswordInput.setErrors(null);

      this.errorPasswordLength = passwordInput.value != null && passwordInput.value.length >= 8;
      this.errorPasswordUpper = new RegExp('[A-Z]{1,}').test(passwordInput.value);
      this.errorPasswordLower = new RegExp('[a-z]{1,}').test(passwordInput.value);
      this.errorPasswordNumber = new RegExp('[0-9]{1,}').test(passwordInput.value);
      this.errorPasswordSpecial = new RegExp('[!"#$%&\'()*+,-.\/:;<=>?@\[\\\]^_`{|}~]{1,}').test(passwordInput.value);

      if (passwordInput.value !== confirmPasswordInput.value)
        return confirmPasswordInput.setErrors({ notEquivalent: true });

      return null;
    }
  }

}