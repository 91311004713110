<div class="sso-container container">

    <div *ngIf="loading">
        <mat-spinner strokeWidth="10" mode="indeterminate" diameter="50"></mat-spinner>
    </div>

    <div>

        <h2 class="mat-h2">
            <mat-error>{{error}}</mat-error>
        </h2>

    </div>

</div>