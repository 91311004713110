import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from '../services/user/user.service';
import * as _moment from 'moment';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-count-balance',
  templateUrl: './count-balance.component.html',
  styleUrls: ['./count-balance.component.scss']
})
export class CountBalanceComponent implements OnInit {

  total: number;
  recordsPerPage: number = 10;
  loading: boolean;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[];
  formFilter: FormGroup;
  noResults: boolean;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService
  ) {
    this.displayedColumns = ['data', 'attivita', 'negozio', 'puntinau'];
    this.dataSource = new MatTableDataSource<any>([]);

    this.formFilter = this.formBuilder.group({
      startDate: [null],
      endDate: [null],
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }, 50);

    this.paginator._intl.itemsPerPageLabel = 'Elementi mostrati per pagina: ';
    this.paginator._intl.previousPageLabel = 'Pagina precedente';
    this.paginator._intl.nextPageLabel = 'Pagina successiva';
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length == 0 || pageSize == 0)
        return `0 di ${length}`;

      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} di ${length}`;
    }

    this.getSell(null, null);
  }

  private getSell(startDate?: string, endDate?: string) {
    this.loading = true;
    this.userService.sell(startDate, endDate).subscribe(
      data => {
        this.loading = false;
        this.total = data.size;
        this.noResults = this.total === 0;
        this.dataSource.data = this.mapping(data.results);
        this.dataSource.paginator = this.paginator;
      });
  }

  public filterTable(values: { startDate: any, endDate: any }) {
    const momentStart = values.startDate != null ? _moment(values.startDate).format('YYYY-MM-DD') : null;
    const momentEnd = values.endDate != null ? _moment(values.endDate).format('YYYY-MM-DD') : null;
    this.getSell(momentStart, momentEnd);
  }

  public clearDate(clean: string) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.formFilter.get(clean).setValue(null);
    this.getSell(this.formFilter.get('startDate').value, this.formFilter.get('endDate').value);
  }

  private mapping(data): any[] {
    data.forEach(element => {
      switch (element.type) {
        case 'REMOVE_POINTS':
          element.type = 'PUNTI PER RESI';
          break;
        case 'REDEMPTION':
          element.type = 'BUONO SCONTO';
          break;
        case 'ADD_POINTS':
          if (element.store)
            element.type = 'PUNTI PER ACQUISTI';
          else
            element.type = 'PUNTI PER REGALO';
          break;
      }
    });
    return data;
  }

}
