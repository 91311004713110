import { Injectable } from '@angular/core';
import { environment as ENV } from "../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class RewardService {
  private loyaltyBaseUrl: string;
  private nauBaseUrl: string;

  constructor(private httpClient: HttpClient, private errorService: ErrorService) {
    this.loyaltyBaseUrl = `${ENV.loyaltyBaseUrl}`;
    this.nauBaseUrl = `${ENV.nauBaseUrl}/nau`;
  }

  /**
   * 
   * @returns 
   */
  public rewardList(): Observable<any> {
    return this.httpClient.get<any>(`${this.loyaltyBaseUrl}/reward`)
      .pipe(catchError(this.errorService.handleError));
  }

  /**
   * 
   * @param code 
   * @returns 
   */
  public redemptionReward(code: string): Observable<any> {
    return this.httpClient.post<any>(`${this.nauBaseUrl}/user/reward/${code}`, {})
      .pipe(catchError(this.errorService.handleError));
  }

  /**
   * Reward of the user
   * @returns 
   */
  public rewardRedeemed(): Observable<any> {
    return this.httpClient.get<any>(`${this.loyaltyBaseUrl}/reward/redeemed`)
      .pipe(catchError(this.errorService.handleError));
  }

}
