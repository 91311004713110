import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loyalty-info',
  templateUrl: './loyalty-info.component.html',
  styleUrls: ['./loyalty-info.component.scss']
})
export class LoyaltyInfoComponent implements OnInit {



  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }, 50);
  }

}
