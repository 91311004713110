import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user/user.service';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit {

  loading: boolean;
  error: string;
  thankyoupage: boolean;
  form: FormGroup;
  err: boolean;

  errorPasswordLength: boolean;
  errorPasswordUpper: boolean;
  errorPasswordLower: boolean;
  errorPasswordNumber: boolean;
  errorPasswordSpecial: boolean;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userService: UserService
  ) {
    this.thankyoupage = false;
    this.loading = false;
    this.err = false;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      password: [null, [Validators.required, Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!\"#$%&'()*+,-.\/:;<=>?@\[\\\]^_`{|}~])[A-Za-z0-9!\"#$%&'()*+,-.\/:;<=>?@\[\\\]^_`{|}~]{8,30}$")]],
      confirmPassword: [null, Validators.required]
    }, { validator: this.checkPassword() });
  }

  public sendActivate(values: { password: string }) {
    let token = this.route.snapshot.queryParams['token'];
    if (this.form.valid) {
      this.loading = true;
      this.userService.activate(token, values.password).subscribe(
        (res) => {
          this.loading = false;
          this.thankyoupage = true
        },
        (error) => {
          this.err = true;
          if (error == 'Not Found')
            this.error = 'Utente non trovato o link scaduto';
          else
            this.error = error;

          this.loading = false;
        },
        () => {
          this.loading = false;
          this.thankyoupage = true
        }
      );
    }
  }

  private checkPassword() {
    return (group: FormGroup) => {
      const passwordInput = group.controls['password'];
      const confirmPasswordInput = group.controls['confirmPassword'];

      confirmPasswordInput.setErrors(null);

      this.errorPasswordLength = passwordInput.value != null && passwordInput.value.length >= 8;
      this.errorPasswordUpper = new RegExp('[A-Z]{1,}').test(passwordInput.value);
      this.errorPasswordLower = new RegExp('[a-z]{1,}').test(passwordInput.value);
      this.errorPasswordNumber = new RegExp('[0-9]{1,}').test(passwordInput.value);
      this.errorPasswordSpecial = new RegExp('[!"#$%&\'()*+,-.\/:;<=>?@\[\\\]^_`{|}~]{1,}').test(passwordInput.value);

      if (passwordInput.value !== confirmPasswordInput.value)
        return confirmPasswordInput.setErrors({ notEquivalent: true });

      return null;
    }
  }

}
