import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Profile } from '../beans/profile';
import { UserService } from '../services/user/user.service';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {

  formSurvey: FormGroup;
  loading: boolean;
  error: boolean;
  profile: Profile;
  resQuestions: any[];
  responses: any[];
  code: string;
  thankyoupage: boolean;
  other: boolean;
  beforeQuestion: number;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private formBuilder: FormBuilder,
  ) {
    this.resQuestions = [];
    this.responses = [];
    this.loading = false;
    this.error = false;
    this.other = false;


    this.formSurvey = this.formBuilder.group({
      responseNote: [null]
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }, 50);

    this.userService.user().subscribe(profile => {
      this.profile = profile;

      this.userService.survey().subscribe(res => {
        this.resQuestions = res['questions'];
        this.code = res['code']
      });
    });

  }

  public checkRadio(check, index, responseIndex, responseNote, question) {
    let response = { questionIndex: index, responseIndex: responseIndex, responseNote: null };
    if (check === "Altro") {
      this.other = true;
      response = { questionIndex: index, responseIndex: responseIndex, responseNote: responseNote }
      this.beforeQuestion = question;
    } else if (check != "Altro" && this.beforeQuestion === question) {
      this.other = false;
      response = { questionIndex: index, responseIndex: responseIndex, responseNote: null }
    }

    let found = -1;
    if (this.responses.length > 0) {
      for (let i = 0; i < this.responses.length; i++) {
        if (this.responses[i]['questionIndex'] === response.questionIndex) {
          found = i;
        }
      }
      if (found > -1) {
        this.responses[found] = response;
      }
      else
        this.responses.push(response);
    }
    else
      this.responses.push(response);
  }

  public survey() {
    if (this.resQuestions.length === this.responses.length) {
      this.loading = true;
      this.error = false;
      let survey = {
        token: this.route.snapshot.queryParams['token'],
        surveyCode: this.code,
        responses: this.responses
      }

      this.userService.surveySubmit(survey).subscribe(
        () => {
          this.loading = false;
          this.thankyoupage = true
        },
        err => {
          this.loading = false;
          this.thankyoupage = false;
          this.error = true;
        }
      )
    }
  }

}
