import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import * as _moment from 'moment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  returnUrl: string;
  formLogin: FormGroup;
  error: string;
  userNotActive: boolean;
  expiredPassword: boolean;
  loading: boolean;
  hide: boolean;
  actualYear: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
  ) {
    this.hide = true;
  }

  ngOnInit(): void {
    this.actualYear = _moment().format('YYYY');
    if (!this.route.snapshot.queryParams['returnUrl'])
      this.authService.logout();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';

    this.formLogin = this.formBuilder.group({
      username: [null, [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,63}$")]],
      password: [null, [Validators.required]],
      remember: [false]
    });
  }

  public login(values: { username: string, password: string, remember: boolean }) {
    if (this.formLogin.valid) {
      this.loading = true;
      this.authService.login(values.username, values.password, values.remember).subscribe(
        () => {
          this.loading = false;
          this.router.navigateByUrl(this.returnUrl);
        },
        (error) => {
          switch (error) {
            case 'USER_NOT_ACTIVE':
              this.userNotActive = true;
              break;
            case 'Password scaduta':
              this.expiredPassword = true;
              this.error = undefined;
              break;
            default:
              this.userNotActive = false;
              this.expiredPassword = false;
              this.error = "Username o password errate. Riprova!";;
              break;
          }
          this.loading = false;
        });
    }
  }

}
