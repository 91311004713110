import { Component, OnInit } from '@angular/core';
import { RewardService } from '../services/reward.service';

@Component({
  selector: 'app-reward',
  templateUrl: './reward.component.html',
  styleUrls: ['./reward.component.scss']
})
export class RewardComponent implements OnInit {
  rewardList: any[];
  loading: number;
  error: string;
  thankyoupage: boolean;

  constructor(
    private rewardService: RewardService
  ) {
    this.rewardList = [];
    this.loading = -1;
    this.thankyoupage = false;
  }

  ngOnInit(): void {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }, 50);

    this.rewardService.rewardList().subscribe(
      res => {
        let orderArray = res['results'];
        orderArray.sort((a, b) => a['points'] - b['points']);
        this.rewardList = orderArray;
      }
    );

  }

  public redemptionReward(code: string, index: number) {
    this.loading = index;
    this.rewardService.redemptionReward(code).subscribe(
      res => {
        this.loading = -1;
        this.thankyoupage = true;
      },
      err => {
        this.error = err;
        setTimeout(() => {
          this.loading = -1;
        }, 2000);
      });

  }

}
