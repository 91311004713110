import { Injectable } from '@angular/core';
import { environment as ENV } from "../../../environments/environment";
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../error.service';
import { Profile } from 'src/app/beans/profile';
import { Survey } from 'src/app/beans/survey';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private nauBaseUrl: string;
  private loyaltycard: string;
  private profile: Profile;
  private subjectProfile = new Subject<any>();

  constructor(private httpClient: HttpClient, private errorService: ErrorService) {
    this.nauBaseUrl = `${ENV.nauBaseUrl}/nau`;
    this.loyaltycard = `${ENV.loyaltyBaseUrl}`;
  }

  public user(): Observable<Profile> {
    return this.httpClient.get<Profile>(`${this.nauBaseUrl}/user`)
      .pipe(catchError(this.errorService.handleError));
  }

  public setProfile(profile: Profile) {
    this.profile = profile;
    this.subjectProfile.next(this.profile);
  }

  public getProfile() {
    return this.profile;
  }

  public onChangeProfile(): Observable<any> {
    return this.subjectProfile.asObservable();
  }

  public survey(): Observable<any> {
    return this.httpClient.get<any>(`${this.nauBaseUrl}/user/survey`)
      .pipe(catchError(this.errorService.handleError));
  }

  /**
   * @param survey 
   * @returns 
   */
  public surveySubmit(survey: Survey): Observable<Profile> {
    return this.httpClient.post<Profile>(`${this.nauBaseUrl}/user/survey`, survey)
      .pipe(catchError(this.errorService.handleError));
  }


  /**
   * Reset della password
   * @param token 
   * @param password 
   * @returns 
   */
  public resetPassword(token: string, password: string): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.nauBaseUrl}/user/resetPassword`, { token: token, password: password })
      .pipe(catchError(this.errorService.handleError));
  }


  /**
 * Reset della password
 * @param token Il token ricevuto nella mail
 * @param password La nuova password
 */
  public requestResetPassword(email: string): Observable<void> {
    return this.httpClient.post<void>(`${this.nauBaseUrl}/user/resetPasswordToken`, { email: email })
      .pipe(catchError(this.errorService.handleError));
  }

  /**
   * Re-invia l'email di attivazione
   * @param email 
   * @returns 
   */
  public resendActivateToken(email: string): Observable<void> {
    return this.httpClient.post<void>(`${this.nauBaseUrl}/user/retry/activateToken`, { email: email })
      .pipe(catchError(this.errorService.handleError));
  }

  /**
   * 
   * @param token 
   * @returns 
   */
  public activate(token: string, password: string): Observable<any> {
    return this.httpClient.post<boolean>(`${this.nauBaseUrl}/user/activate`, { token: token, password: password })
      .pipe(catchError(this.errorService.handleError));
  }

  /**
   * 
   * @returns 
   */
  public sell(startDate?: string, endDate?: string): Observable<any> {
    let params = new HttpParams();
    if (startDate)
      params = params.append("startDate", startDate);
    if (endDate)
      params = params.append("endDate", endDate);
    return this.httpClient.get<{ size: number, results: any[] }>(`${this.nauBaseUrl}/user/sell`, { params: params })
      .pipe(catchError(this.errorService.handleError));
  }

}
