import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, empty, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(public authService: AuthService, private router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (new RegExp('auth/login|auth/sso/login|activateToken|activate|resetPasswordToken|resetPassword').test(req.url))
            return next.handle(req);

        req = this.setRequestHeaders(req, true);

        return next.handle(req).pipe(
            catchError(response => {
                if (response.status === 401)
                    this.authService.logout();
                else
                    return throwError(response);
            })
        );

    }

    /**
     * Setta gli headers della richiesta
     * @param request La richiesta su cui settare gli headers
     * @param withAuth TRUE se aggiungiamo l'Authorization
     */
    private setRequestHeaders(request: HttpRequest<any>, withAuth: boolean): HttpRequest<any> {
        let headers = request.headers.append('Content-Type', 'application/json; charset=UTF-8');

        if (withAuth === true) {
            const token = this.authService.getToken();
            headers = headers.append('Authorization', `Bearer ${token}`);
        }

        return request.clone({
            headers: headers,
            url: `${request.url}`
        });
    }

}