<div class="request-activate-container container">

    <div *ngIf="loading">
        <mat-spinner strokeWidth="10" mode="indeterminate" diameter="50"></mat-spinner>
    </div>

    <div *ngIf="thankyoupage">
        <h1 class="mat-display-1">Ti è stata inviata una e-mail con la procedura di attivazione</h1>
    </div>

    <mat-error>{{error}}</mat-error>

</div>