import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RewardComponent } from './reward/reward.component';
import { CountBalanceComponent } from './count-balance/count-balance.component';
import { FaqComponent } from './faq/faq.component';
import { HomeComponent } from './home/home.component';
import { SsoComponent } from './sso/sso.component';
import { SurveyComponent } from './survey/survey.component';
import { AuthGuardService as AuthGuard } from './services/auth/auth-guard.service';
import { LoginComponent } from './login/login.component';
import { ResetPasswordEmailComponent } from './reset-password-email/reset-password-email.component';
import { RequestResetPasswordComponent } from './request-reset-password/request-reset-password.component';
import { ActivateComponent } from './activate/activate.component';
import { LoyaltyInfoComponent } from './loyalty-info/loyalty-info.component';
import { RequestActivateComponent } from './request-activate/request-activate.component';
import { RequestActivateAccountComponent } from './request-activate-account/request-activate-account.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'activate', component: ActivateComponent },
  { path: 'requestActivate', component: RequestActivateComponent },
  { path: 'requestActivateAccount', component: RequestActivateAccountComponent },
  { path: 'requestResetPassword', component: RequestResetPasswordComponent },
  { path: 'resetPassword', component: ResetPasswordEmailComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'countbalance', component: CountBalanceComponent, canActivate: [AuthGuard] },
  { path: 'faq', component: FaqComponent, canActivate: [AuthGuard] },
  { path: 'reward', component: RewardComponent, canActivate: [AuthGuard] },
  { path: 'sso', component: SsoComponent },
  { path: 'survey', component: SurveyComponent, canActivate: [AuthGuard] },
  { path: 'loyaltyInfo', component: LoyaltyInfoComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
