import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss']
})
export class SsoComponent implements OnInit {
  returnUrl: string;
  loading: boolean;
  error: string;

  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    this.loading = true
  }

  ngOnInit(): void {
    let token = this.route.snapshot.queryParams['token'];
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || `/home?token=${token}`;
    this.authService.ssoLogin(token).subscribe(
      res => {
        this.loading = false;
        this.router.navigateByUrl(this.returnUrl);
      },
      err => {
        this.error = err;
        this.loading = false;
        this.router.navigate['sso'], { queryParams: { token: token } };
      }
    )
  }
}
