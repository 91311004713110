<form class="count-balance-container container" [formGroup]="formFilter" autocomplete="off" (ngSubmit)="filterTable(formFilter.value)">

    <div class="countbalance-title">
        <h1>ESTRATTO CONTO</h1>
        <p>CONSULTA LO STORICO DELLE TUE ATTIVITÀ</p>
    </div>

    <div class="countbalance-calendar-filter">

        <mat-form-field class="flex33 btn-date-border field-1">
            <input matInput [matDatepicker]="validStart" placeholder="DATA INIZIO" (focus)="validStart.open()" formControlName="startDate">
            <button *ngIf="formFilter.get('startDate').value != null" mat-icon-button (click)="clearDate('startDate')"> <mat-icon matDatepickerToggleIcon>clear</mat-icon></button>
            <mat-datepicker-toggle matSuffix [for]="validStart"></mat-datepicker-toggle>
            <mat-datepicker #validStart></mat-datepicker>
            <img src="assets/images/calendar.png" *ngIf="formFilter.get('startDate').value === null">
        </mat-form-field>

        <mat-form-field class="flex33 btn-date-border field-2">
            <input matInput [matDatepicker]="validEnd" placeholder="DATA FINE" (focus)="validEnd.open()" formControlName="endDate">
            <button *ngIf="formFilter.get('endDate').value != null" mat-icon-button (click)="clearDate('endDate')"><mat-icon matDatepickerToggleIcon >clear</mat-icon></button>
            <mat-datepicker-toggle matSuffix [for]="validEnd"></mat-datepicker-toggle>
            <mat-datepicker #validEnd></mat-datepicker>
            <img src="assets/images/calendar.png" *ngIf="formFilter.get('endDate').value === null">
        </mat-form-field>

        <p class="mtop-half">
            <input type="submit" value="Filtra la ricerca" class="btn-filter btn-primary" />
        </p>

    </div>

    <div class="countbalance-calendar-table">

        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

        <mat-table class="mat-elevation-z2" [dataSource]="dataSource" class="mat-elevation-z2">

            <ng-container matColumnDef="data">
                <mat-header-cell *matHeaderCellDef mat-sort-header> DATA </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="DATA"> {{ element.date | date: 'dd/MM/yyyy HH:mm' }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="attivita">
                <mat-header-cell *matHeaderCellDef mat-sort-header> ATTIVITA' </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="ATTIVITA'"> {{ element.type === 'PUNTI PER ACQUISTI' && element.total === null ? 'PUNTI BONUS' : element.type === 'PUNTI PER RESI' && element.total === null ? 'PUNTI BONUS' : element.type }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="negozio">
                <mat-header-cell *matHeaderCellDef mat-sort-header> NEGOZIO </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="NEGOZIO"> {{ element.store }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="puntinau">
                <mat-header-cell *matHeaderCellDef mat-sort-header> PUNTI NAU! </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="PUNTI NAU!"> {{ element.type === 'PUNTI PER RESI' ? '-' : '' }} {{ element.points }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

        </mat-table>

        <mat-paginator #paginator [length]="total" [pageSize]="recordsPerPage"></mat-paginator>

        <div class="not-found" *ngIf="noResults">
            <p>Non ci sono risultati</p>
        </div>

    </div>

</form>