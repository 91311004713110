<div class="container-account container body-content">

    <div class="title-nau mbottom-double mtop-double">
        <h1>NAU!CARD {{actualYear}}</h1>
        <h2>Con NAU!CARD ottieni buoni sconto per i tuoi futuri acquisti</h2>
    </div>

    <form class="row-content" [formGroup]="formLogin" autocomplete="off" (ngSubmit)="login(formLogin.value)">
        <div class="col-md-6 with-50 col-padding-left">
            <div class="box box-light box-login">
                <h5>ACCEDI</h5>
                <p>Se possiedi già un account, accedi con la tua email.</p>

                <div id="form-post">

                    <label for="UserName">E-MAIL</label>
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="E-mail" formControlName="username">
                        <mat-error
                            *ngIf="formLogin.get('username').errors && formLogin.get('username').errors['pattern']">
                            Attenzione: l'indirirzzo e-mail inserito non è corretto</mat-error>
                    </mat-form-field>

                    <label for="UserName">PASSWORD</label>
                    <mat-form-field appearance="outline">
                        <input [type]="hide ? 'password' : 'text'" matInput placeholder="Password"
                            formControlName="password">
                        <button class="hide" mat-icon-button type="button" matSuffix (click)="hide = !hide"
                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                    </mat-form-field>

                    <mat-error *ngIf="error">{{error}}</mat-error>

                    <div class="form-group mtop-30">

                        <div class="row dflex">

                            <div class="col-md-4">
                                <div class="checkbox">
                                    <input id="RememberMe" name="RememberMe" type="checkbox" formControlName="remember">
                                    <label for="RememberMe">Ricordami</label>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

                <div class="login-container">
                    <div *ngIf="!loading">
                        <p class="mtop-half"><input type="submit" value="Accedi" class="btn btn-primary" /></p>
                    </div>
                    <div class="spinner">
                        <mat-spinner strokeWidth="10" mode="indeterminate" diameter="50" *ngIf="loading"></mat-spinner>
                    </div>
                    <div *ngIf="userNotActive">
                        <p class="mtop-half"><input type="button" value="Attiva il tuo account" class="btn btn-primary"
                                [routerLink]="['/requestActivate']"
                                [queryParams]="{em: formLogin.get('username').value}" /></p>
                    </div>
                </div>

                <p class="mtop-50 t-underline"><a [routerLink]="['/requestResetPassword']">PASSWORD DIMENTICATA?</a></p>

            </div>
        </div>

        <div class="col-md-6 with-50 col-padding-right">
            <div class="box box-light box-login sso-box">

                <div class="box-sso">
                    <h5 class="h5 mbottom-half">CONTINUA CON UN ACCOUNT NAU.IT</h5>
                    <p class="mbottom-half">Se hai già un account su nau.it e sei in possesso di una NAU!CARD accedi
                        subito con le credenziali di nau.it</p>
                    <p class="mtop-half">
                        <a type="button" class="btn btn-primary" href="/nau/api/auth/sso/redirect">Accedi</a>
                    </p>
                    <p class="ftext-bottom">Se non sei in possesso di una NAU!CARD richiedila subito<a target="blank"
                            href="https://www.nau.it/it/nau-card"> QUI</a>.</p>
                </div>

            </div>

            <div class="box box-light box-login sso-box">

                <div class="box-sso">
                    <h5 class="h5 mbottom-half">ATTIVAZIONE ACCOUNT NAUCARD.IT</h5>
                    <p class="mbottom-half">Hai la Nau Card e non hai ricevuto l’email di attivazione account o il link
                        è scaduto?</p>
                    <p class="mtop-half">
                        <a type="button" class="btn btn-primary" [routerLink]="['/requestActivateAccount']">Attiva il
                            tuo account</a>
                    </p>
                </div>

            </div>

        </div>
    </form>

    <div class="row mtop-double">
        <div class="col-md-12 text-center">

            <h2>TANTI VANTAGGI IN ESCLUSIVA PER TE</h2>

            <p class="mbottom-double">
                <a class="btn-link" [routerLink]="['/loyaltyInfo']">
                    Scopri di più
                </a>

            </p>

        </div>
    </div>

    <div class="row mbottom-double display-flex">

        <div class="col-md-6 text-center separator-rv mbottom">

            <p><img src="assets/images/icon-storico.png" alt=""></p>

            <p>Consulta lo storico dei tuoi acquisti</p>

        </div>

        <div class="col-md-6 text-center mbottom">

            <p><img src="assets/images/icon-trasforma.png" alt=""></p>

            <p>Trasforma i tuoi acquisti in premi a tua scelta</p>

        </div>

    </div>

</div>