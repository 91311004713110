<div id="activate-container" class="container" *ngIf="!thankyoupage && !err">

    <h1 class="mat-h1">ATTIVAZIONE</h1>

    <form [formGroup]="form" (submit)="sendActivate(form.value)">

        <mat-form-field appearance="outline" id="new-passord">

            <input matInput placeholder="Password" type="password" formControlName="password">

            <mat-hint>La password deve:
                <section>
                    <mat-icon [ngStyle]="{'color': errorPasswordLength ? 'green' : 'red'}">{{ errorPasswordLength ? 'check_circle' : 'warning' }}</mat-icon> essere lunga almeno 8 caratteri</section>
                <section>
                    <mat-icon [ngStyle]="{'color': errorPasswordUpper ? 'green' : 'red'}">{{ errorPasswordUpper ? 'check_circle' : 'warning' }}</mat-icon> contenere una lettera maiuscola</section>
                <section>
                    <mat-icon [ngStyle]="{'color': errorPasswordLower ? 'green' : 'red'}">{{ errorPasswordLower ? 'check_circle' : 'warning' }}</mat-icon> contenere una lettera minuscola</section>
                <section>
                    <mat-icon [ngStyle]="{'color': errorPasswordNumber ? 'green' : 'red'}">{{ errorPasswordNumber ? 'check_circle' : 'warning' }}</mat-icon> contenere un numero</section>
                <section>
                    <mat-icon [ngStyle]="{'color': errorPasswordSpecial ? 'green' : 'red'}">{{ errorPasswordSpecial ? 'check_circle' : 'warning' }}</mat-icon> contenere un carattere speciale (!"#$%&'()*+,-./:;&lt;=&gt;?@[\]^_`&#123;|&#125;~)</section>
            </mat-hint>

        </mat-form-field>

        <mat-form-field appearance="outline">

            <input matInput placeholder="Conferma password" type="password" formControlName="confirmPassword">

            <mat-error *ngIf="form.get('confirmPassword').errors && form.get('confirmPassword').errors['required']">Attenzione: la conferma della password è obbligatoria</mat-error>

            <mat-error *ngIf="form.get('confirmPassword').errors && form.get('confirmPassword').errors['notEquivalent']">Attenzione: le due password non coincidono</mat-error>

        </mat-form-field>

        <button mat-flat-button *ngIf="!loading">CONFERMA</button>

        <mat-progress-spinner mode="indeterminate" diameter="50" *ngIf="loading"></mat-progress-spinner>

        <p class="mat-error text-center error-text">{{ error }}</p>

    </form>

</div>

<div *ngIf="err" id="activate-container" class="container">

    <h2 class="mat-h2">
        <mat-error>Errore durante l 'attivazione: {{error}}</mat-error>
    </h2>

    <button class="btn btn-primary" mat-flat-button [routerLink]="['/home']">TORNA INDIETRO</button>

</div>

<div id="thankyoupage" *ngIf="thankyoupage && !err" class="container">

    <h1 class="mat-display-1">Attivazione Confermata</h1>

    <button class="btn btn-primary" mat-flat-button [routerLink]="['/home']">Accedi all'area riservata</button>

</div>