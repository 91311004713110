<div id="reset-panel" class="container" *ngIf="!thankyoupage">

    <p>Hai dimenticato la password?</p>
    <p>Inserisci l’indirizzo e-mail che hai utilizzato in fase di registrazione, riceverai una mail da Nau Customer Service</p>

    <form [formGroup]="form" (submit)="requestResetPassword(form.value)">

        <mat-form-field appearance="outline">

            <input matInput placeholder="Indirizzo e-mail" formControlName="username">

            <mat-error *ngIf="form.get('username').errors && form.get('username').errors['pattern']">Attenzione: l'indirirzzo e-mail inserito non è corretto</mat-error>

        </mat-form-field>

        <button mat-flat-button *ngIf="!loading" class="btn">CONFERMA</button>

        <mat-progress-spinner mode="indeterminate" diameter="50" *ngIf="loading"></mat-progress-spinner>

        <p class="mat-error text-center error-text">{{ error }}</p>

    </form>

</div>


<div id="thankyoupage" *ngIf="thankyoupage">

    <h1 class="mat-display-1">Attendi l'email per resettare la password</h1>

</div>