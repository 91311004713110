import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from "@angular/material/card";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatRadioModule } from "@angular/material/radio";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatIconModule } from "@angular/material/icon";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTableModule } from "@angular/material/table";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { HomeComponent } from './home/home.component';
import { CountBalanceComponent } from './count-balance/count-balance.component';
import { FaqComponent } from './faq/faq.component';
import { SurveyComponent } from './survey/survey.component';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { RewardComponent } from './reward/reward.component';
import { SsoComponent } from './sso/sso.component';
import { LoginComponent } from './login/login.component';
import { AuthInterceptor } from './services/auth/auth.interceptor';
import { ResetPasswordEmailComponent } from './reset-password-email/reset-password-email.component';
import { RequestResetPasswordComponent } from './request-reset-password/request-reset-password.component';
import { ActivateComponent } from './activate/activate.component';
import { LoyaltyInfoComponent } from './loyalty-info/loyalty-info.component';
import { RequestActivateComponent } from './request-activate/request-activate.component';
import { RequestActivateAccountComponent } from './request-activate-account/request-activate-account.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CountBalanceComponent,
    FaqComponent,
    SurveyComponent,
    RewardComponent,
    SsoComponent,
    LoginComponent,
    ResetPasswordEmailComponent,
    RequestResetPasswordComponent,
    ActivateComponent,
    LoyaltyInfoComponent,
    RequestActivateComponent,
    RequestActivateAccountComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatInputModule,
    MatCheckboxModule,
    MatTableModule,
    MatFormFieldModule,
    MatMenuModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatRadioModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressBarModule,
    MatSelectModule,
    MatIconModule,
    MatAutocompleteModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'it-IT'
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
