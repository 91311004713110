<div class="faq-container container">

    <div class="box-header">

        <div class="h1-content">
            <h1>COME FUNZIONA NAU!CARD</h1>
        </div>

        <div class="box-header-content">

            <div class="box-1 separator-rv box">
                <p class="point"><a>1</a></p>
                <h2>RICHIEDI</h2>
                <p class="point-descriprion">Richiedi la tua NAU!CARD in negozio o richiedila subito <a target="blank" href="https://www.nau.it/it/nau-card">online</a>.</p>
            </div>

            <div class="box-2 separator-rv box">
                <p class="point"><a>2</a></p>
                <h2>ACCUMULA</h2>
                <p class="point-descriprion">Utilizza in negozio o online sul sito <a target="blank" href="https://www.nau.it">nau.it</a> la NAU!CARD ad ogni tuo acuisto: potrai accumulare un punto NAU! per ogni euro speso.</p>
            </div>

            <div class="box-3 box">
                <p class="point"><a>3</a></p>
                <h2>RISCATTA IL PREMIO</h2>
                <p class="point-descriprion">Più acquisti, più punti accumuli: per te buoni sconto da utilizzare per i prossimi acquisti NAU!</p>
            </div>

        </div>

    </div>

    <div class="box-center-content">

        <div class="h2-content">
            <h2>TRASFORMA I TUOI PUNTI NAU! IN BUONI SCONTO</h2>
        </div>

        <div class="box-scount-content">

            <div class="col-xs-6 col-md-2">
                <div class="btn-anim static mbottom-half">
                    <div class="btn btn-reward">
                        BUONO SCONTO
                        <strong class="valore-voucher">5 €</strong>
                    </div>
                </div>
                <p class="mbottom-double"><strong>200 PUNTI NAU!</strong></p>
            </div>

            <div class="col-xs-6 col-md-2">
                <div class="btn-anim static mbottom-half">
                    <div class="btn btn-reward">
                        BUONO SCONTO
                        <strong class="valore-voucher">10 €</strong>
                    </div>
                </div>
                <p class="mbottom-double"><strong>300 PUNTI NAU!</strong></p>
            </div>

            <div class="col-xs-6 col-md-2">
                <div class="btn-anim static mbottom-half">
                    <div class="btn btn-reward">
                        BUONO SCONTO
                        <strong class="valore-voucher">15 €</strong>
                    </div>
                </div>
                <p class="mbottom-double"><strong>400 PUNTI NAU!</strong></p>
            </div>

            <div class="col-xs-6 col-md-2">
                <div class="btn-anim static mbottom-half">
                    <div class="btn btn-reward">
                        BUONO SCONTO
                        <strong class="valore-voucher">20 €</strong>
                    </div>
                </div>
                <p class="mbottom-double"><strong>500 PUNTI NAU!</strong></p>
            </div>

            <div class="col-xs-6 col-md-2">
                <div class="btn-anim static mbottom-half">
                    <div class="btn btn-reward">
                        BUONO SCONTO
                        <strong class="valore-voucher">50 €</strong>
                    </div>
                </div>
                <p class="mbottom-double"><strong>1000 PUNTI NAU!</strong></p>
            </div>

        </div>

    </div>
    <hr>
    <div class="box-footer">
        <h2>E IN PIU', TANTI ALTRI VANTAGGI IN ESCLUSIVA PER TE</h2>
    </div>
    <hr>
    <p class="text-center">CONSULTA LO STORICO DEI TUOI ACQUISTI E IL SALDO PUNTI</p>
    <hr>
    <p class="text-center">ENTRA IN UN MONDO DI OFFERTE E PROMOZIONI DEDICATE</p>
    <hr>
    <p class="text-center">RESTA SEMPRE AGGIORNATO SU VANTAGGI RISERVATI AI POSSESSORI DI NAU!CARD</p>
    <hr>

    <div class="box-app">
        <h2>SCARICA LA NAU!CARD APP</h2>
        <p>Visualizza in ogni momento il tuo saldo punti, ricevi offerte personalizzate e scopri il negozio NAU! più vicino a te: scarica la app da Google Play o App Store, porta il mondo NAU!CARD sempre con te!</p>

        <div class="box-icon">
            <div class="margin-box-icon">
                <img src="assets/images/ico-google-play.png">
            </div>
            <div>
                <img src="assets/images/ico-app-store.png">
            </div>
        </div>

    </div>
    <hr>

    <div class="box-regulation">

        <div>
            <p>PER SAPERNE DI PIÙ:</p>
        </div>

        <div>
            <p class="mtop-half">
                <a type="button" class="btn btn-primary" href="/uploads/pdf/regolamento-nau.pdf" target="_blank">Scarica il regolamento completo</a>
            </p>
        </div>

    </div>

</div>