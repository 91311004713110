import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user/user.service';

@Component({
  selector: 'app-request-activate',
  templateUrl: './request-activate.component.html',
  styleUrls: ['./request-activate.component.scss']
})
export class RequestActivateComponent implements OnInit {

  form: FormGroup;
  error: string;
  loading: boolean;
  thankyoupage: boolean;
  token: string;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.loading = true
    this.route.queryParams.subscribe(res => {
      this.userService.resendActivateToken(res['em']).subscribe(
        () => {
          this.thankyoupage = true;
          this.loading = false;
        },
        err => {
          this.loading = false;
          this.error = err;
        },
        () => {
          this.thankyoupage = true;
          this.loading = false;
        }
      )
    });
  }

}
