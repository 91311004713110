<div class="header-image-nau container">
    <div class="logo-container-nau">

        <div class="logo-content" [ngStyle]="isLogged ? {'margin-bottom':'0px'} : {'margin-bottom':'16px'}">

            <div class="logo" [routerLink]="['home']">
                <img src="assets/images/logo.png" style="width: 100px;">
            </div>

        </div>

        <div class="profile-esc" *ngIf="isLogged">
            <a class="a-mr">{{profile?.firstname | uppercase}}</a>
            <a class="a-mr">{{profile?.lastname | uppercase}}</a>
            <a class="exit" (click)="logout()">ESCI</a>
        </div>

        <div class="bk-hero">
            <img src="assets/images/header.jpg">
        </div>
    </div>
</div>

<div id="tab-container" class="container" *ngIf="isLogged">

    <div class="nav-container">
        <a [routerLink]="['home']" id="tab" routerLinkActive #home="routerLinkActive" [ngStyle]="home.isActive ? {'color': '#000', 'font-weight': '600', 'text-decoration': 'underline'} : {}">HOME</a>
        <a [routerLink]="['countbalance']" id="tab" routerLinkActive #countbalance="routerLinkActive" [ngStyle]="countbalance.isActive ? {'color': '#000', 'font-weight': '600', 'text-decoration': 'underline'} : {}">ESTRATTO CONTO</a>
        <a [routerLink]="['reward']" id="tab" routerLinkActive #reward="routerLinkActive" [ngStyle]="reward.isActive ? {'color': '#000', 'font-weight': '600', 'text-decoration': 'underline'} : {}">RICHIEDI PREMIO</a>
        <a [routerLink]="['faq']" id="tab" routerLinkActive #faq="routerLinkActive" [ngStyle]="faq.isActive ? {'color': '#000', 'font-weight': '600', 'text-decoration': 'underline'} : {}">COME FUNZIONA</a>
    </div>

</div>

<hr id="hr-tab" class="hr-container container" *ngIf="isLogged">

<router-outlet></router-outlet>

<footer>
    <div class="container">
        <p class="text-center-xs"><strong>© {{actualYear}} - NAU! Spa - P.I 02836320123</strong></p>
    </div>
</footer>